import { Component, Prop, Vue } from 'vue-property-decorator'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { loadStripe } from '@stripe/stripe-js'

@Component({
  name: 'StripeCard'
})
export default class StripeCard extends Vue {
  @Prop({ default: '' }) stripeKey!: string

  cardValue = '';

  get stripeCardRules () {
    return {
      required: true,
      stripe: this.cardValue
    }
  }

  data () {
    return {
      stripe: null,
      elements: null,
      card: null,
      stripeError: null,
      isStripeComplete: false,
      errorMessages: {
        incomplete_number: 'The card number is incomplete.',
        invalid_number: 'The card number is not a valid credit card number.',
        invalid_expiry_month: "The card's expiration month is invalid.",
        invalid_expiry_year: "The card's expiration year is invalid.",
        incomplete_expiry: 'The expiration date of your card is incomplete.',
        invalid_cvc: "The card's security code is invalid.",
        expired_card: 'The card has expired.',
        incorrect_cvc: "The card's security code is incorrect.",
        incorrect_zip: "The card's zip code failed validation.",
        card_declined: 'The card was declined.',
        missing: 'There is no card on a customer that is being charged.',
        processing_error: 'An error occurred while processing the card.',
        rate_limit: 'An error occurred due to requests hitting the API too quickly.',
        incomplete_cvc: 'The security code for your card is incomplete.',
        invalid_expiry_year_past: "The card's expiration year is invalid.",
        incomplete_zip: 'Your zip code is incomplete.'
      }
    }
  }

  async mounted () {
    this.$data.stripe = await loadStripe(this.stripeKey)
    this.$emit('stripe-change', this.$data.stripe)
    try {
      this.$data.elements = this.$data.stripe.elements()
      const style = {
        base: {
          fontSize: '16px',
          '::placeholder': {
            color: '#00000099'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }
      this.$data.card = this.$data.elements.create('card', { style })
      this.$data.card.mount('#card-element')
      this.$data.card.addEventListener('change', (event: any) => {
        this.$data.isStripeComplete = event.complete
        this.cardValue = event.complete && !event.error ? 'completed' : ''
        if (event.value.postalCode && event.complete) {
          this.$emit('zip-code-change', event.value.postalCode)
        }
        this.toggleError(event)
      })
      this.$emit('card-change', this.$data.card)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  toggleError (event: any) {
    if (event.error) {
      this.$data.stripeError = this.$data.errorMessages[event.error.code]
    } else {
      this.$data.stripeError = null
    }
  }
}
